import React from "react"
import Helmet from "react-helmet"
import { graphql, Link, withPrefix } from "gatsby"

import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"
import BulletinSection from "../../components/sections/bulletin_section"

const BulletinsPage = ({ data }) => {
  const page = data.wagtail.page

  let ancestor = { children: [], button: {} }
  data.wagtail.page.ancestors.forEach(item => {
    if ("children" in item) {
      ancestor = item
    }
  })

  return (
    <>
      <Layout
        isContent={true}
        subMenu={ancestor.children}
        button={ancestor.button}
        parent={"officials"}
        siteTitle={page.title}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/officials">Officials</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1>{page.title}</h1>
                <p>{page.descriptionSimple}</p>
              </div>
            </div>
          </div>
        </section>

        <BulletinSection data={page.children} />

        <CTABlock data={page.fullContent[0]} />
      </Layout>
      <Helmet>
        <script src={withPrefix("scripts/custom.js")} type="text/javascript" />
      </Helmet>
    </>
  )
}

export const query = graphql`
  {
    wagtail {
      page(slug: "bulletins") {
        ... on BulletinsPage {
          title
          descriptionSimple
          children {
            ... on BulletinPage {
              live
              title
              slug
              bulletintypeName
              date
              detail
              isProtected
              feedImage {
                src
              }
            }
          }
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
          ancestors {
            ... on OfficialsPage {
              children {
                title
                slug
                ... on SimplePage {
                  live
                  isProtected
                }
                ... on PositionsPage {
                  live
                  isProtected
                }
                ... on OfficialPage {
                  live
                  isProtected
                }
                ... on BulletinsPage {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
          }
        }
      }
    }
  }
`
export default BulletinsPage
