import React from "react"
import { Link } from "gatsby"
import Select from "../Select"

import Moment from "moment"

class BulletinSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: undefined,
      data: props.data.filter(item => item.live),
      initialData: props.data.filter(item => item.live),
      text: undefined,
      loadmore: 20,
    }
  }

  onChange = event => {
    let _data = this.state.initialData
    const { text, filter } = this.state
    if (_data) {
      if (text) {
        _data = _data.filter(
          item =>
            !this.state.text ||
            item.detail.toLowerCase().includes(text) ||
            item.title.toLowerCase().includes(text)
        )
      }
      _data = _data.filter(
        item =>
          filter === undefined ||
          filter.length === 0 ||
          item.bulletintypeName.toLowerCase() === filter
      )
      this.setState({ data: _data })
    }
  }

  search = event => {
    const text = event.target.value
    let _data = this.state.initialData

    if (!text) {
      this.setState({ data: _data, text: "" })
    }

    if (_data && text) {
      _data = _data.filter(
        item =>
          this.state.filter === undefined ||
          this.state.filter.length === 0 ||
          item.bulletintypeName.toLowerCase() === this.state.filter
      )
      _data = _data.filter(
        item =>
          !text ||
          item.detail.toLowerCase().includes(text) ||
          item.title.toLowerCase().includes(text)
      )
      this.setState({ data: _data, text: text })
    }
  }

  onClick = event => {
    this.setState({ loadmore: this.state.loadmore + 20 })
  }

  onReset = event => {
    this.setState({ loadmore: 9 })
  }

  getLoadMore = () => {
    const total = this.state.data ? this.state.data.length : 0
    if (total < 20) {
      return null
    }
    if (this.state.loadmore >= total) {
      return <></>
    }
    return (
      <button onClick={this.onClick} className="button-load-more">
        {" "}
        Load More{" "}
      </button>
    )
  }

  async componentDidMount() {
    Moment.locale("en")
  }

  handleChange = ({ name, value }) =>
    this.setState({ [name]: value }, this.onChange)

  render() {
    const { filter } = this.state
    return (
      <>
        <section className="main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="select-items-area office">
                  {" "}
                  <div className="box">
                    <p className="p-bold pt-30 pb-10">Category</p>
                    <Select
                      name="filter"
                      placeholder="All"
                      value={filter}
                      handleChange={this.handleChange}
                      selectOptions={["all", "announcement", "update"]}
                    />
                  </div>
                  <div className="box">
                    <p className="p-bold pt-30 pb-10">Search</p>
                    <div className="search-box">
                      <input
                        type="search"
                        placeholder="Enter keyword"
                        value={this.state.text}
                        onChange={this.search}
                      />
                      <button>
                        <img src={`../../images/search.png`} alt="src" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-30 mb-80">
          <div className="container">
            <div className="row">
              {this.state.data.map((item, index) => {
                if (index < this.state.loadmore) {
                  return (
                    <div key={index.toString()} className="col-md-4 col-sm-6">
                      <div className="event-slide latest-bulletin">
                        <div className="latest-bulletin-item bulletin-section-item item">
                          <div className="latest-bulletin-item-layer"></div>
                          <div className="latest-bulletin-content">
                            <h5>{item.bulletintypeName}</h5>
                            <p className="p-legal mt-15 mb-15">
                              {Moment(item.date).format("MMMM D, YYYY")}
                            </p>
                            <h3>{item.title}</h3>
                            <p className="p-legal">{item.detail}</p>
                          </div>
                          <Link
                            to={`/officials/bulletins/${item.slug}/`}
                            className="read-more"
                          >
                            Read more{" "}
                            <img
                              className="arrow arrow-right"
                              src={`../images/arrow-right-2.png`}
                              alt="arrow"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                }
                return ""
              })}
            </div>
            <div className="row">
              <div className="col-12 align-items-center text-center">
                {this.getLoadMore()}
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default BulletinSection
